import GEOFencesView from "@/views/company/geofence/Index.vue";
import GEOFenceDetailsView from "@/views/company/geofence/Show.vue"
import GEOFenceFormView from "@/views/company/geofence/Form.vue";
import GEOAssignmentsView from "@/views/company/geofence/Assignments.vue";
import GEOAssignmentsFormView from "@/views/company/geofence/AssignmentsForm.vue";

export default [
    {
        path: '/geo-fence',
        name: 'company-geo-fence',
        meta: {
            key: 'geo-fence',
            title: 'Geo Fence',
            auth: true,
        },
        component: GEOFencesView
    },
    {
        path: '/geo-fence/create',
        name: 'company-geo-fence-create',
        meta: {
            key: 'geo-fence',
            title: 'Create Geo Fence',
            auth: true,
        },
        component: GEOFenceFormView
    },
    {
        path: '/geo-fence/:id',
        name: 'company-geo-fence-details',
        meta: {
            key: 'geo-fence',
            title: 'Geo Fence Details',
            auth: true,
        },
        component: GEOFenceDetailsView
    },
    {
        path: '/geo-fence/edit/:id',
        name: 'company-geo-fence-edit',
        meta: {
            key: 'geo-fence',
            title: 'Edit Geo Fence',
            auth: true,
        },
        component: GEOFenceFormView
    },
    {
        path: '/geo-fence/assignments',
        name: 'company-geo-fence-assignments',
        meta: {
            key: 'geo-fence',
            title: 'Geo Fence Assignments',
            auth: true,
        },
        component: GEOAssignmentsView
    },
    {
        path: '/geo-fence/assignments/assign',
        name: 'company-geo-fence-assignments-assign',
        meta: {
            key: 'geo-fence',
            title: 'Geo Fence Assignments',
            auth: true,
        },
        component: GEOAssignmentsFormView
    },
];
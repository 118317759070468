import { getSidebarMenuIcons } from '@/boot/sidebar-icons';
import apis from "@/server/api/company/index";

export default {
    namespaced: true,

    state: () => ({
        sidebarMenuItems: {
            'dashboard': {
                key: 'dashboard',
                title: 'Dashboard',
                route: '/',
                icon: getSidebarMenuIcons('dashboard', false),
                active: false,
                hasChildren: false,
            },
            'monitor': {
                key: 'monitor',
                title: 'Monitor',
                route: '/monitor',
                icon: getSidebarMenuIcons('monitor', false),
                active: false,
                hasChildren: false,
            },
            'my-team': {
                key: 'my-team',
                title: 'My Team',
                route: '/team',
                icon: getSidebarMenuIcons('my-team', false),
                active: false,
                hasChildren: false,
            },
            'manage-employee': {
                key: 'manage-employee',
                title: 'Manage Employee',
                route: '/manage-employee',
                icon: getSidebarMenuIcons('manage-employee', false),
                active: false,
                hasChildren: false,
            },
            'attendance': {
                key: 'attendance',
                title: 'Attendance',
                route: '/attendance',
                icon: getSidebarMenuIcons('attendance', false),
                active: false,
                hasChildren: false,
            },
            'task': {
                key: 'task',
                title: 'Task',
                route: '/task',
                icon: getSidebarMenuIcons('task', false),
                active: false,
                hasChildren: false,
            },
            'poi': {
                key: 'poi',
                title: 'POI',
                route: '/poi',
                icon: getSidebarMenuIcons('poi', false),
                active: false,
                hasChildren: false,
            },
            'geo-fence': {
                key: 'geo-fence',
                title: 'Geo Fence',
                route: '/geo-fence',
                icon: getSidebarMenuIcons('geo-fence', false),
                active: false,
                hasChildren: false,
            },
            // 'visit': {
            //     key: 'visit',
            //     title: 'Visit',
            //     route: '/visit',
            //     icon: getSidebarMenuIcons('visit', false),
            //     active: false,
            //     hasChildren: false,
            // },
            // 'leave': {
            //     key: 'leave',
            //     title: 'Leave',
            //     route: '/leave',
            //     icon: getSidebarMenuIcons('leave', false),
            //     active: false,
            //     hasChildren: false,
            // },
            // 'expense': {
            //     key: 'expense',
            //     title: 'Expenses',
            //     route: '/expense',
            //     icon: getSidebarMenuIcons('expense', false),
            //     active: false,
            //     hasChildren: false,
            // },
            // 'customer': {
            //     key: 'customer',
            //     title: 'Customer',
            //     route: '/customer',
            //     icon: getSidebarMenuIcons('customer', false),
            //     active: false,
            //     hasChildren: false,
            // },
            // 'insight': {
            //     key: 'insight',
            //     title: 'Insight',
            //     route: '/insight',
            //     icon: getSidebarMenuIcons('insight', false),
            //     active: false,
            //     hasChildren: false,
            // },
            // 'form': {
            //     key: 'form',
            //     title: 'Forms',
            //     route: '/form',
            //     icon: getSidebarMenuIcons('form', false),
            //     active: false,
            //     hasChildren: false,
            // },
            'report': {
                key: 'report',
                title: 'Reports',
                route: '/reports',
                icon: getSidebarMenuIcons('report', false),
                active: false,
                hasChildren: false,
            },
            'setting': {
                key: 'setting',
                title: 'Settings',
                route: '/settings',
                icon: getSidebarMenuIcons('setting', false),
                active: false,
                hasChildren: true,
                children: [
                    {
                        title: 'Work Force',
                        items: [
                            {
                                title: 'Departments',
                                route: '/departments',
                                active: false,
                            },
                            {
                                title: 'Designations',
                                route: '/designations',
                                active: false,
                            },
                        ],
                    },
                    {
                        title: 'Work Schedule',
                        items: [
                            {
                                title: 'Shifts & Workdays',
                                route: '/shifts-workdays',
                                active: false,
                            },
                            {
                                title: 'Holiday',
                                route: '/holidays',
                                active: false,
                            },
                        ],
                    },
                ]
            },
        },
        systemColors: [],
        companyPoiList: [],
    }),

    actions: {
        onChangeSidebarMenu: ({ commit }, { key }) => {
            if (key || false) {
                commit("CHANGE_ACTIVE_MENU_ITEM", key);
            }
        },

        getSystemColors: async ({ commit, state }) => {
            if (state.systemColors.length == 0) {
                const colors = await apis.getSystemColors();
                commit("SET_SYSTEM_COLORS", colors);
            }
        },

        getCompanyPoiCategory: async ({ commit, state }) => {
            if (state.companyPoiList.length == 0) {
                const data = await apis.getCompanyPoiCategory();
                commit("SET_COMPANY_POI_LIST", data);
            }
        }
    },

    mutations: {
        CHANGE_ACTIVE_MENU_ITEM: (state, value) => {
            Object.values(state.sidebarMenuItems).forEach(item => {
                item.active = false;
                item.icon = getSidebarMenuIcons(item.key, false);
            });
            const item = state.sidebarMenuItems[value];
            if (item) {
                item.active = true;
                item.icon = getSidebarMenuIcons(value, true);
            }
        },

        SET_SYSTEM_COLORS: (state, data) => {
            state.systemColors = data;
        },

        SET_COMPANY_POI_LIST: (state, data) => {
            state.companyPoiList = data;
        }
    },
}
<template>
    <div class="">
        <div class="h-[100vh] w-[100px] absolute hover:w-[200px]">
            <SidenavteamMenu />
        </div>

        <HeaderNavbar title="Geofence Details" />

        <div class="ml-sidebar">
            <div v-if="loading">
                Loading...
            </div>
            <div v-else>
                <div class="mx-4 app-table my-2">
                    <div class="d-flex justify-content-between mb-2">
                        <div class="text-left">
                            <h2 style="font-size: 22px;">{{ geofence.name || '' }}</h2>
                            <p class="mb-0">{{ geofence.description || '' }}</p>
                        </div>
                        <div>
                            <router-link :to="{ name: 'company-geo-fence-assignments' }" class="btn text-button">
                                <i class="fa fa-pen text-info"></i> Assign Geofence
                            </router-link>
                        </div>
                    </div>
                    <div id="mapContainer">
                        <div class="map-full-screen">
                            <button class="btn" @click="handleFullScreenMap">
                                <i class="fas fa-expand"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="mx-4 mb-5">
                    <div class="d-flex justify-content-between align-items-center">
                        <router-link :to="{ name: 'company-geo-fence-create' }" class="btn text-button">
                            <i class="fa fa-plus text-info"></i> Create Geofence
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import SidenavteamMenu from "@/components/menu/SideNavbarTeam.vue";
import HeaderNavbar from "@/components/menu/headerdashboard.vue";
import apis from "@/server/api/company/index";

export default {
    components: { SidenavteamMenu, HeaderNavbar },

    data: () => ({
        loading: false,
        geofence: {},
    }),

    mounted() {
        this.init();
    },

    methods: {

        async init() {
            if (this.$route.params.id || false) {
                this.geofence = await apis.getGeofence(this.$route.params.id);
                this.initializedMap();

                const coordinatesString = this.geofence.geom.replace("POLYGON ((", "").replace("))", "");
                const coordinatePairs = coordinatesString.split(", ");
                const polygonCoords = coordinatePairs.map(pair => pair.split(" "));
                console.log(polygonCoords)
                if (polygonCoords.length > 0) {
                    L.polygon(polygonCoords, { color: 'blue' }).addTo(this.map);
                    this.map.flyTo(polygonCoords[0], 18);
                }
            }
        },

        initializedMap() {
            this.map = L.map("mapContainer", {
                zoomControl: true,
                zoomAnimation: false,
                fadeAnimation: true,
                markerZoomAnimation: true,
                drawControl: true,
            }).setView([23.8187676, 90.4314902], 12);

            L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
                attribution: '',
                minZoom: 0,
                maxZoom: 22,
                maxNativeZoom: 18,
            }).addTo(this.map);

        },

    },
};
</script>

<style scoped>
#mapContainer {
    width: 100%;
    height: 80vh;
    z-index: 1;
}
</style>